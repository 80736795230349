import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from './plugins/axios'
// import Debounce from '@/plugins/debounce.js'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
// Vue.component('Debounce', Debounce)

// let lastRoute = router.history._startLocation
// if(lastRoute && lastRoute === '/nc9'){
// 	// 将页面重定向到指定URL
// 	window.location.href = "https://www.service-survey.vip/home";
// }

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
