<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@media screen and (max-width: 450px) {
  .el-notification {
    width: 90%;
  }
}
</style>
