import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/warranty',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/warranty',
        name: 'warranty',
        component: () => import('@/views/warranty.vue')
      },
      {
        path: '/nc9',
        name: 'nc9',
        component: () => import('@/views/warranty.vue')
      },
      {
        path: '/t12',
        name: 't12',
        component: () => import('@/views/warranty.vue')
      },
	  {
	    path: '/a1',
	    name: 'a1',
	    component: () => import('@/views/warranty.vue')
	  },
	  {
	    path: '/buds',
	    name: 'buds',
	    component: () => import('@/views/warranty.vue')
	  },
      {
        path: '/finish',
        name: 'Finish',
        component: () => import('@/views/Finish.vue')
      },
		
		  {
		    path: '/warrantyNew',
		    name: 'warrantyNew',
		    component: () => import('@/views/warrantyNew.vue')
		  },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
